

.albumcontainer {
    display: grid;
    grid-template-columns: auto auto auto auto;
    row-gap: 50px;
    column-gap: 10px;
}

.card-content {
    background-color: rgb(247, 239, 230);
}

#stats {
    margin: 1em;
}

@media (max-width: 768px) {
    .albumcontainer {
        grid-template-columns: auto; /* Ocupa todo el ancho disponible */
      /* Cambios de estilo para dispositivos móviles */
    }
  }
  
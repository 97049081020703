.imagenbio {
    background-color: blue;
}

.social-icons {
    display: flex;
    gap: 10px; /* Ajusta el espacio entre los iconos según sea necesario */ /* Ajusta el margen superior según sea necesario */
}
  
.social-icons a {
    color: #000100; /* Cambia el color de los enlaces según tus preferencias */
}
  
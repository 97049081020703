.notas {
    display:grid;
    
}

.nota {
    position: relative;
}

.titulo {
    font-weight:900;
    color:black;
    font-family: 'Helvetica Neue';
    font-size:xxx-large;
}

.subtitulo {
    font-weight:300;
    color:black;
    font-family: 'Helvetica Neue';
    margin-bottom: 2%;
}

.contenido {
    font-weight:400;
    color:black;
    font-family: 'Helvetica Neue';
    font-size:normal;
    text-rendering: optimizeLegibility;
    margin-bottom: 5%;
}

.footlink {
    /* Estilos base */
    position: absolute;
    bottom: 5%;
    left: 0%;
    background-color: black;
    color: white;
    font-weight: 900;
    font-size: large;
    padding: 4%;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    transform-origin: bottom left; /* Define el origen de la transformación */
}

.footlink:hover {
    /* Efecto de hover */
    color: white;
    background-color: rgb(0, 120, 239);
    transform: scale(1.1);
    box-shadow: 0px 0px 10px 0px rgb(241, 39, 140);
}

.container {
    backgroundColor: color;
    padding: 70;
    width: '100%'; 
    height: '100%'; 
}

  



